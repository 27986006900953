<template>
  <div>
  </div>
</template>

<script>

export default {
  name: 'Home'
}
</script>
