<template>
  <div>
    <br><br>
    <b-row align-h="center">
      <b-col md="4" class="card">

        <h1>Bienvenido</h1>
        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
          <b-form-group
                        horizontal
                        :label-cols="4"
                        breakpoint="md"
                        label="Usuario:">
            <b-form-input v-model="form.usuario" autofocus>
            </b-form-input>
          </b-form-group>
          <b-form-group
                        horizontal
                        :label-cols="4"
                        breakpoint="md"
                        label="Contraseña:">
            <b-form-input type="password" v-model="form.pass">
            </b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">Aceptar</b-button>
        </b-form>
        <br>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col md="4">
        <b-alert :show="showError" variant="danger">Usuario y/o contraseña incorrectos.</b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
import AppService from '@/services/AppService'
import store from '../store.js'
import router from '../router'
export default {
  name: 'login',
  data () {
    return {
      state: store.state,
      form: {
        usuario: '',
        pass: ''
      },
      show: true,
      showError: false
    }
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      this.login()
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.form.usuario = ''
      this.form.pass = ''
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => { this.show = true })
    },
    async login () {
      this.showError = false
      try {
        let response = await AuthenticationService.login({
          nombre: this.form.usuario,
          password: this.form.pass
        })
        sessionStorage.setItem('token', response.data.token || '')
        sessionStorage.setItem('refresh_token', response.data.refresh_token || '')
        
        response = await AppService.menu()
        this.state.usuario = response.data.usuario
        sessionStorage.setItem('usuario', response.data.usuario || '')
        this.state.empresa = response.data.empresa
        this.state.menu = response.data.menu
        //window.location = '/'
        router.replace('/')
      } catch (error) {
        console.log(error)
        this.showError = true
      }
    }
  },
  mounted () {
    this.state.menu = null
  }
}
</script>
<style scoped>
.card {
  background: #bbbb;
}
</style>
