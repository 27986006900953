import axios from 'axios'
import router from '../router'

export default () => {
  const httpClient = axios.create({

  })
  httpClient.interceptors.request.use((config) => {
    const token = sessionStorage.getItem('token');
    // const token = store.state.access_token
    config.headers.Authorization =  token ? `Bearer ${token}` : ''
    return config
  })
  httpClient.interceptors.response.use((response) => {
    return response
  }, async function(error) {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      const refreshToken = sessionStorage.getItem('refresh_token');
      const usuario = sessionStorage.getItem('usuario');
      return httpClient.post('/api/refresh-token', {
        usuario: usuario,
        refresh_token: refreshToken
      }).then(response => {
        const token = response.data.token || '';
        sessionStorage.setItem('token', token);
        originalRequest.headers.Authorization = token ? `Bearer ${token}` : '';
        return httpClient(originalRequest);
      }).catch(error => {
        if (error.response.status === 400) {
          router.replace('/login');
          return Promise.reject(new Error('refresh token expired'));
        } else {
          return Promise.reject(error);
        }
      })
    } else {
      return Promise.reject(error);
    }
  })

  return httpClient
}
