<template>
  <div id="app">
    <div v-if="state.menu">
      <b-navbar type="dark" variant="primary" toggleable="lg">
        <b-navbar-toggle target="nav_dropdown_collapse"></b-navbar-toggle>
        <b-collapse is-nav id="nav_dropdown_collapse">
          <b-navbar-nav>
            <b-navbar-brand right>
              {{state.empresa}}
            </b-navbar-brand>
            <b-nav-item href="/#/">
              Inicio
            </b-nav-item>
            <b-nav-item-dropdown v-for="item in state.menu" :text="item.titulo" :key="item.titulo">
              <b-dropdown-item v-for="el in item.elementos" :href="el.href" :key="el.titulo">
                {{el.titulo}}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown :text="state.usuario" right>
              <b-dropdown-item @click="onLogout()">Cerrar sesion</b-dropdown-item>
              <b-dropdown-item @click="cambiarPassword()">Cambiar contraseña</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import AppService from '@/services/AppService'
import router from './router'
import store from './store.js'
export default {
  name: 'app',
  data () {
    return {
      state: store.state,
      empresa: ''
    }
  },
  methods: {
    async getMenu () {
      try {
        const response = await AppService.menu()
        this.state.usuario = response.data.usuario
        this.state.empresa = response.data.empresa
        if (this.state.usuario === '') {
          console.log('Usuario en blanco.')
          router.replace('/login')
          return
        }
        this.state.menu = response.data.menu
      } catch (error) {
        console.log('Current route: ', router.currentRoute.name)
        if (error.response.status === 401) {
          if (router.currentRoute.name !== 'Login') {
            router.replace('/login')
          }
          
        }
        console.log('Falla:', error.response.status)
      }
    },
    checkToken () {
      const token = sessionStorage.getItem('token') || ''
      if ((token === '') || (this.state.usuario === '')) {
        if (router.currentRoute.name !== 'Login') {
          router.replace('/login')
        }
      }
    },
    async onLogout () {
      try {
        sessionStorage.setItem('token', '')
        sessionStorage.setItem('refresh_token', '')
        router.replace('/login')
      } catch (error) {
        console.log(error)
      }
    },
    cambiarPassword () {
      router.replace('/cambiar-password')
    }
  },
  mounted () {
    this.checkToken()
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
