<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-alert :show="mensaje.dismissCountDown"
          dismissible
          variant="warning"
          @dismiss-count-down="countDownChanged">
          {{mensaje.texto}}
        </b-alert>
      </div>
    </div>
    <div v-if="tieneAcceso" class="row" >
      <div class="col-lg-3">
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-12">
            <h4>Ventas por producto por dia</h4>
          </div>
        </div>
        <b-row>
          <b-col>
            <b-row>
              <b-col class="text-left">
                <label :for="fecha_ini">Fecha inicial:</label>
                <b-form-input id="fecha_ini" type="date" v-model="fecha_ini"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-left">
                <label :for="fecha_fin">Fecha final:</label>
                <b-form-input id="fecha_fin" type="date" v-model="fecha_fin"></b-form-input>
              </b-col>
            </b-row>
            <b-form-group
              class="text-left"
              label="Sucursal:"
            >
              <b-form-select
                v-model="sucursal_sel"
                :options="sucursalOptions"
              >
              </b-form-select>
            </b-form-group>
            <br>
            <b-button variant="primary"
              @click="onExcel">
              Generar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
  import AppService from '@/services/AppService'
  import store from '../store.js'
  export default {
    name: 'ventas',
    data () {
      return {
        state: store.state,
        mensaje: {
          dismissSecs: 0,
          dismissCountDown: 0,
          texto: ''
        },
        tieneAcceso: true,
        filtro: true,
        items: [],
        totalRows: 0,
        perPage: 20,
        currentPage: 1,
        pageOptions: [ 20, 30, 50 ],
        fecha_ini: '',
        fecha_fin: '',
        sucursal_sel: '',
        sucursalOptions: [],
      }
    },
    methods: {
      onExcel () {
        this.mensaje.texto = ''
        if (this.sucursal_sel == '') {
          this.mensaje.texto = 'Por favor elija una sucursal'
        }
        if (this.fecha_ini > this.fecha_fin) {
          this.mensaje.texto = 'La fecha inicial no puede ser mayor que la fecha final'
        }
        if (this.mensaje.texto !== '') {
          this.mensaje.variant = 'warning'
          this.mensaje.dismissCountDown = 5
          return
        }
        let query = 'fecha_ini=' + this.fecha_ini + '&fecha_fin=' + this.fecha_fin
        query += '&sucursal=' + this.sucursal_sel
        query += '&excel=si'
        AppService.DownloadFile('api/ventas-producto-dia?' + query, 'VentasProductoDia.xlsx')
      },
      async cargarSucursales () {
        try {
          this.sucursalOptions = []
          let query = ''
          const response = await AppService.Sucursales(query)
          for (let rec of response.data) {
            this.sucursalOptions.push({value: rec.id, text: rec.nombre_corto})
          }
        } catch (error) {
          if (error.response) {
            console.log('Sucursales: status = ' + error.response.status)
          }
          console.log('Error al cargar las sucursales', error)
          // this.error = error.response.data.error
        }
      },
      countDownChanged (dismissCountDown) {
        this.mensaje.dismissCountDown = dismissCountDown
      }
    },
    computed: {

    },
    mounted () {
      let now = new Date()
      let dd = now.getDate()
      let mm = now.getMonth() + 1
      let yyyy = now.getFullYear()
      if (dd < 10) dd = '0' + dd
      if (mm < 10) mm = '0' + mm
      let fecha = `${yyyy}-${mm}-${dd}`
      this.fecha_ini = fecha
      this.fecha_fin = fecha
      this.cargarSucursales()
    }
  }
</script>
<style>
.right {
  text-align: right;
}
</style>
