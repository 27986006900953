<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-alert :show="mensaje.dismissCountDown"
          dismissible
          variant="warning"
          @dismiss-count-down="countDownChanged">
          {{mensaje.texto}}
        </b-alert>
      </div>
    </div>
    <div v-if="verListado" class="container-fluid">
      <div v-if="tieneAcceso" class="row" >
        <div class="col-lg-2 p-2 container-fluid" v-if="filtro">
          <b-card
            header="Filtro"
            header-text-variant="white"
            header-bg-variant="primary"
            border-variant="primary">
            <h5 slot="header">Filtro</h5>
            <b-row>
              <b-col class="text-left">
                <label :for="fecha_ini">Fecha inicial:</label>
                <b-form-input id="fecha_ini" type="date" v-model="fecha_ini"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-left">
                <label :for="fecha_fin">Fecha final:</label>
                <b-form-input id="fecha_fin" type="date" v-model="fecha_fin"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-left">
                <label :for="producto">Producto:</label>
                <b-form-input
                  id="producto_buscar"
                  v-model="producto"
                  type="text"
                  maxlength="30">
                </b-form-input>
              </b-col>
            </b-row>
            <br>
            <b-form-group
              class="text-left"
              label="Sucursales"
            >
              <b-form-checkbox
                v-for="option in sucursalOptions"
                v-model="sucursales_sel"
                :key="option.value"
                :value="option.value"
              >
                {{option.text}}
              </b-form-checkbox>
            </b-form-group>
            <b-row>
              <b-col>
                <br>
                <b-button variant="primary"
                  @click="onAplicarFiltro">
                  Buscar
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <br>
                Encontrados: {{totalRows}}
              </b-col>
            </b-row>
          </b-card>
        </div>
        <div class="col-lg-10">
          <div class="row">
            <div class="col-md-12">
              <h4>Ventas por producto</h4>
            </div>
          </div>
          <b-row>
            <b-col md="4">
              <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" />
            </b-col>
            <b-col md=4>
              <b-row>
                <b-col class="text-right">Por página:</b-col>
                <b-col>
                  <b-form-select :options="pageOptions" v-model="perPage" />
                </b-col>
              </b-row>
            </b-col>
            <b-col md=4 class="text-right">
              <b-button variant="success"
                @click="onExcel">
                <b-icon icon="file-earmark-excel" ></b-icon>
              </b-button>
              <b-button variant="info"
                @click="onPdf">
                <b-icon icon="file-earmark-pdf" ></b-icon>
              </b-button>
            </b-col>
          </b-row>
          <b-table
            ref="table"
            striped
            hover
            small
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :fields="fields"
            :items="itemsProvider"
            :current-page="currentPage"
            :per-page="perPage" caption-top>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppService from '@/services/AppService'
  import store from '../store.js'
  export default {
    name: 'ventas',
    data () {
      return {
        state: store.state,
        mensaje: {
          dismissSecs: 0,
          dismissCountDown: 0,
          texto: ''
        },
        verListado: true,
        tieneAcceso: true,
        filtro: true,
        items: [],
        totalRows: 0,
        perPage: 20,
        currentPage: 1,
        pageOptions: [ 20, 30, 50 ],
        sortBy: '',
        sortDesc: false,
        fecha_ini: '',
        fecha_fin: '',
        producto: '',
        sucursales_sel: [],
        sucursalOptions: [],
        fields: [
          {
            key: 'nombre',
            label: 'Producto',
            class: 'text-left',
            sortable: true
          },
          {key: 'cantidad',
            class: 'right',
            sortable: true,
            formatter: (value) => { return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }
          },
          {key: 'importe',
            class: 'right',
            sortable: true,
            formatter: (value) => { return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }
          },
        ]
      }
    },
    methods: {
      onAplicarFiltro () {
        this.$refs.table.refresh()
      },
      onRegresar () {
        this.verListado = true
      },
      onExcel () {
        let sucursales = this.sucursales_sel.join(',')
        let query = 'fecha_ini=' + this.fecha_ini + '&fecha_fin=' + this.fecha_fin
        query += '&producto=' + this.producto
        query += '&sucursales=' + sucursales
        query += '&excel=si'
        query += '&sort_by=' + this.sortBy || ''
        query += '&sort_desc=' + (this.sortDesc ? 'si' : 'no')
        // window.open('api/ventas-producto?' + query)
        AppService.DownloadFile('api/ventas-producto?' + query, 'VentasProducto.xlsx')
      },
      onPdf () {
        let sucursales = this.sucursales_sel.join(',')
        let query = 'fecha_ini=' + this.fecha_ini + '&fecha_fin=' + this.fecha_fin
        query += '&producto=' + this.producto
        query += '&sucursales=' + sucursales
        query += '&pdf=si'
        query += '&sort_by=' + this.sortBy || ''
        query += '&sort_desc=' + (this.sortDesc ? 'si' : 'no')
        // window.open('api/ventas-producto?' + query)
        AppService.DownloadFile('api/ventas-producto?' + query, 'VentasProducto.pdf')
        /*
        let request = new XMLHttpRequest()
        console.log('Header ', this.state.access_token)
        
        request.open("GET", 'api/ventas-producto?' + query)
        request.setRequestHeader('Authorization', `Bearer ${this.state.access_token}`)
        request.responseType = 'arraybuffer'
        request.onload = function(e) {
          console.log(e)
          let file = new Blob([this.response], {type: 'application/pdf'})
          let fileUrl = window.URL.createObjectURL(file)
          console.log(fileUrl)
          window.open(fileUrl)
        }
        request.send()
        */
      },
      itemsProvider (ctx) {
        let sucursales = this.sucursales_sel.join(',')
        let parent = this
        // Es probable que todavia las fechas esten vacias.
        if (this.fecha_ini === '') {
          return
        }

        let query = '?fecha_ini=' + this.fecha_ini + '&fecha_fin=' + this.fecha_fin
        query += '&producto=' + this.producto
        query += '&sucursales=' + sucursales
        query += '&per_page=' + ctx.perPage + '&current_page=' + ctx.currentPage
        query += '&sort_by=' + (this.sortBy || '') + '&sort_desc=' + (this.sortDesc ? 'si' : 'no')
        let promise = AppService.VentasProducto(query)
        // Must return a promise that resolves to an array of items
        return promise.then((response) => {
          let items = response.data.items
          parent.totalRows = response.data.rowsCount
          // Must return an array of items or an empty array if an error occurred
          return (items || [])
        }).catch((error) => {
          if (error.response) {
            if ((error.response.status === 403) || (error.response.status === 401)) {
              this.tieneAcceso = false
              this.mensaje.variant = 'warning'
              this.mensaje.texto = error.response.data
              this.mensaje.dismissCountDown = 5
            }
          }
        })
      },
      async cargarSucursales () {
        try {
          this.sucursalOptions = []
          let query = ''
          const response = await AppService.Sucursales(query)
          for (let rec of response.data) {
            this.sucursalOptions.push({value: rec.id, text: rec.nombre_corto})
          }
        } catch (error) {
          if (error.response) {
            console.log('Sucursales: status = ' + error.response.status)
          }
          console.log('Error al cargar las sucursales', error)
          // this.error = error.response.data.error
        }
      },
      countDownChanged (dismissCountDown) {
        this.mensaje.dismissCountDown = dismissCountDown
      }
    },
    computed: {

    },
    mounted () {
      let now = new Date()
      let dd = now.getDate()
      let mm = now.getMonth() + 1
      let yyyy = now.getFullYear()
      if (dd < 10) dd = '0' + dd
      if (mm < 10) mm = '0' + mm
      let fecha = `${yyyy}-${mm}-${dd}`
      this.fecha_ini = fecha
      this.fecha_fin = fecha
      this.cargarSucursales()
    }
  }
</script>
<style>
.right {
  text-align: right;
}
</style>
