import Api from '@/services/Api'

export default {
  menu () {
    return Api().get('api/menu')
  },
  Ventas (query) {
    return Api().get('api/ventas' + query)
  },
  Sucursales (query) {
    // const config = { headers: { 'Authorization': `Bearer ${token}` } }
    return Api().get('api/sucursales' + query)
  },

  Empresa () {
    return Api().get('api/empresa')
  },
  CambiarPassword (ctx) {
    return Api().post('api/cambiar-password', ctx)
  },

  Usuarios (query) {
    return Api().get('/api/usuarios' + query)
  },
  ModificarUsuario (ctx, id) {
    return Api().post('/api/usuario/' + id, ctx)
  },
  CrearUsuario (ctx) {
    return Api().post('/api/usuario', ctx)
  },

  VentasProducto (query) {
    // const config = { headers: { 'Authorization': `Bearer ${token}` } }
    return Api().get('api/ventas-producto' + query)
  },
  DownloadFile (url, name) {
    let anchor = document.createElement("a")
    let token = sessionStorage.getItem('token') || ''
    document.body.appendChild(anchor)
    let headers = new Headers()
    headers.append('Authorization', `Bearer ${token}`)

    fetch(url, { headers })
      .then((response) => {
        console.log('Response status: ' + response.status)
        if (response.status != 200) {
          throw new Error('Error al descargar el archivo.')
        } else {
          return response.blob()
        }
      })
      .then(blobby => {
        let objectUrl = window.URL.createObjectURL(blobby)

        anchor.href = objectUrl
        anchor.download = name
        anchor.click()
        window.URL.revokeObjectURL(objectUrl) 
      }).catch((error) => {
        console.log(error)
      })
  }
}
