import Vue from 'vue'
import VueRouter from 'vue-router'
import CambiarPassword from '../views/CambiarPassword.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Ventas from '../views/Ventas.vue'
import VentasProducto from '../views/VentasProducto.vue'
import VentasProductoDia from '../views/VentasProductoDia'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/cambiar-password',
    name: 'CambiarPassword',
    component: CambiarPassword
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/ventas',
    name: 'Ventas',
    component: Ventas
  },
  {
    path: '/ventas-producto',
    name: 'VentasProducto',
    component: VentasProducto
  },
  {
    path: '/ventas-producto-dia',
    name: 'VentasProductoDia',
    component: VentasProductoDia
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
