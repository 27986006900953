<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-alert :show="mensaje.dismissCountDown"
          dismissible
          variant="warning"
          @dismiss-count-down="countDownChanged">
          {{mensaje.texto}}
        </b-alert>
      </div>
    </div>
    <div class="col-md-6 offset-md-3 text-left p-2">
      <b-card header-bg-variant="primary"
        header-text-variant="white"
        header="Cambiar contraseña">
        <b-row>
          <b-col class="text-right" md="4">Contraseña actual:</b-col>
          <b-col>
            <b-form-input
              v-model="password_actual"
              type="password"
              maxlength="20">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right" md="4">Contraseña nueva:</b-col>
          <b-col>
            <b-form-input
              v-model="password_nuevo"
              type="password"
              maxlength="20">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right" md="4">Confirmar:</b-col>
          <b-col>
            <b-form-input
              v-model="conf_password"
              type="password"
              maxlength="20">
            </b-form-input>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col></b-col>
          <b-col>
            <b-button variant="primary"
              @click="onAceptar">
              Aceptar
            </b-button>
            <b-button variant="secondary"
              @click="onRegresar">
              Regresar
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import router from '../router'
import AppService from '@/services/AppService'
export default {
  name: 'CambiarPassword',
  data () {
    return {
      mensaje: {
        dismissSecs: 0,
        dismissCountDown: 0,
        texto: ''
      },
      password_actual: '',
      password_nuevo: '',
      conf_password: ''
    }
  },
  methods: {
    onRegresar () {
      router.replace('/')
    },
    async onAceptar () {
      try {
        let response = null
        this.mensaje.texto = ''
        if ((this.password_actual === '') || (this.password_nuevo === '') || (this.conf_password === '')) {
          this.mensaje.texto = 'Debe llenar los 3 campos.'
        }
        if (this.password_nuevo !== this.conf_password) {
          this.mensaje.texto = 'Las contraseñas no coinciden.'
        }
        if (this.mensaje.texto !== '') {
          this.mensaje.dismissCountDown = 5
          return
        }
        
        response = await AppService.CambiarPassword({
          password_actual: this.password_actual,
          password_nuevo: this.password_nuevo
        })
        if (response.data.message === 'ok') {
          this.onRegresar()
        } else {
          this.mensaje.texto = response.data.message
          this.mensaje.dismissCountDown = 5
        }
      } catch (error) {
        if (error.response) {
          this.mensaje.variant = 'warning'
          this.mensaje.texto = error.response.data
          this.mensaje.dismissCountDown = 5
        }
      }
    },
    countDownChanged (dismissCountDown) {
      this.mensaje.dismissCountDown = dismissCountDown
    }
  }
}
</script>