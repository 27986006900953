import Api from '@/services/Api'

export default {
  login (credentials) {
    // const config = { headers: { 'Content-Type': 'multipart/form-data' } }
    // {withCredentials: true}
    return Api().post('/api/login', credentials, {withCredentials: true})
  },
  logout () {
    return Api().post('/api/logout')
  },
  isLogged () {
    return Api().post('/api/islogged')
  }
}
